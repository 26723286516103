#intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-left: 15%; */
    padding-top: 5%;
    min-height: 100vh;
    /* border: 3px; */
    /* box-sizing: border-box; */
    color: lightblue;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    /* display: block; */
    overflow: hidden;
}

.Typist {
    color: var(--lightest-slate);
    text-align: center;
    font-size: 80px;
    margin-top: -30px;
}
.Typist .Cursor--blinking {
    color: var(--green-bright);
    padding-left: 4px;
}

.Typist .Cursor {
  display: inline-block; }
  .Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.intro-title {
    /* font-family: 'NTR', sans-serif; */
    font-family: sans-serif;
    font-size: 48px;
}

.intro-name{
    /* font-family: 'NTR', sans-serif; */
    font-family: sans-serif;
    color: #065143;
    font-weight: bold;
    font-size: 48px;
    flex-direction: row;
}

a:hover {
    color: #7C77B9;
}

/* .intro-pic {
    margin: 6em auto;
    border-radius: 50%;
    display: inline;
    width: auto;
    margin: 0 auto;
    height: 100%;
}

.intro-pic img {
    margin: 6em auto;
    border-radius: 50%;
    width: 150px;
} */
 
img {
    display: inline;
    width: 300px;
    height: 100%;
    border-radius: 50%;
}