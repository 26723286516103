/* @font-face {
  font-family: "NTR";
  font-weight: 200;
  font-display: fallback;
  src: url("/fonts/NTR-Regular.ttf") format("truetype");
} */

.App {
  /* text-align: center; */
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.App-logo {
  height: 40vmin;
  /* pointer-events: none; */
  display: flex;
  align-items: flex-end;
  border-radius: 1000;
}

/* TODO: Remove if unused */
/* .App-logo:hover {
  animation: App-logo-spin infinite 0.5s linear;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.5s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Note: # vs . is id vs class. id affects only one thing vs class can modify multiple things */
/* TODO: Remove if unused */
/* #content {
  padding-left: 0px;
} */

/* To change color of hyperlink <a href> */
a {
  color: #FCBFB7;
  text-decoration: none;
}

/* On button press, smooth scroll to target destination */
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  /* Note: Below are needed to hide vertical and horizontal scrollbars. */
  /* Scrollbars are created by default when adding a div */
  overflow-y: hidden;
  overflow-x: hidden;
}

#backgroundImage {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}

h1 {
  /* height: 100vh; */
  color: lightblue;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 5%;
}

p {
  color: lightblue;
  /* min-height: 100vh; */
  /* min-width: 100vh; */
  /* margin-left: 30vh; */
  /* margin-right: 30vh; */
  text-align: center;
  font-size: 24px;
}

ul > li {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  margin: 0 20px 20px 0;
}

/* Using this so that background color extends to bottom of page */
body {
  min-height: 100vh;
  min-width: 100vh;
}